.searchInputSuggestions {
  width: 100%;

  position: absolute;
  top: 4.4rem;
  left: -.1rem;

  z-index: 9;

  padding: 8px 0;
  outline: none;
  background-color: white;
  border: .1rem solid darkgrey;
  border-bottom-left-radius: .4rem;
  border-bottom-right-radius: .4rem;

  .searchInputSuggestion {
    height: 4rem;
    font-size: 1.8rem;
    line-height: 4rem;
    text-align: left;
    padding: 0 1.8rem;
    cursor: pointer;

    &:hover, &.selected {
      background-color: whitesmoke;
    }
  }
}

.SuggestionItem{
  padding: 6px 16px;
  display: flex;
  align-items: center;

  &:hover, &.selected {
    background-color: #ECECEC;
    cursor: pointer;

    .deleteKeywordButton {
      height: 28px;
      margin-left: auto;
      visibility: visible;
    }
  }
}

.deleteKeywordButton {
  visibility: hidden;
}
