.SearchWrapper{
    margin-bottom: 20px;
}

.FavoritPageTitle{
    margin-bottom: 15px;
    font-weight: 600;
    text-transform: uppercase;
    color: #808080;
    font-size: 17.5px;
}