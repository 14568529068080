.FormCardComponent{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 16px !important;
}

@media (max-width: 800px) {
    .FormCardComponent {
      flex-direction: column;
      text-align: center;
    }
  }
