.DocumentTitleWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.DocumentDescriptionWrapper{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 20px;
}

.AmendmentsReferencesWrapper{
    background-color: #eff3f6;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
}

.AmendmentsReferencesBox{
    border: 1px solid #eff3f6;
    padding: 1rem 1rem;
}

@media only screen and (max-width: 767px) {
    .DocumentDescriptionWrapper{
        display: grid;
        grid-template-columns: 1fr;
    }
}