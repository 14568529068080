.FavoriteCardTitleWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.FavoritedCardDescriptionsWrapper{
    p{
        margin: 0;
        font-size: 13px;

        span{
            font-size: 14px;
            font-weight: bold;
        }
    }
}

.FavoriteIcon{
    margin-right: 15px;
}