.VerdictTitleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.VerdictDescriptionWrapper{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 20px;
}

@media only screen and (max-width: 767px) {
    .VerdictDescriptionWrapper{
        display: grid;
        grid-template-columns: 1fr;
    }
}