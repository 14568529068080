.LandingWrapper{
    p{
        padding: 0;
        margin: 0;
    }

    // WHAT IS LEXDOKS SECTION
    .WhatIsLexdoksSection{
        background-color: #FBFCFC;

        .WhatIsLexdoksSectionItems{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5rem 0 5rem 0;
            
            .WhatIsLexdoksSectionItem{
                flex: 50%;
                padding: 0 2rem 0 2rem;

                .WhatIsLexdoksTitle{
                    font-size: 3rem;
                    color: '#222835';
                }
            
                .WhatIsLexdoksDescription{
                    font-weight: 400;
                    font-size: 1.3rem;
                }
            }
        }
    }
    @media only screen and (max-width: 399px) {
        .WhatIsLexdoksSectionItems{
 
            .WhatIsLexdoksSectionItem{
 
                .RegisterButton{
                    margin-top: 1.1rem;
                }
            }
        }
     }
    @media only screen and (max-width: 600px) {
       .WhatIsLexdoksSectionItems{
           flex-direction: column;

           .WhatIsLexdoksSectionItem{
               padding: 0 !important;
           }
       }
    }
    //  // WHAT IS LEXDOKS SECTION


    // WHY LEXDOKS SECTION
    .WhyLexdoksSection{
        background-image: linear-gradient(204.33deg, #1976D2 15.57%, #004F9C 92.23%);
        color: #fff;

        .WhyLexdoksSectionItems{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5rem 0 5rem 0;

            .WhyLexdoksSectionItem{
                flex: 50%;
                padding: 0 2rem 0 2rem;

                .WhyLexdoksTitle{
                    font-size: 3rem;
                    color: '#222835';
                }
            
                .WhyLexdoksDescription{
                    font-weight: 400;
                    font-size: 1.3rem;
                }
            }
        }
    }
    @media only screen and (max-width: 600px) {
        .WhyLexdoksSectionItems{
            flex-direction: column;

            .WhyLexdoksSectionItem{
                padding: 0 !important;
            }
        }
    }
    // // WHY LEXDOKS SECTION


    // SERVICES SECTION
    .ServicesSection{
        background-image: linear-gradient(to right, white 50%, #F3F4F5 50%);

        .ServicesSectionItems{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2rem 0 2rem 0;

            .ServicesSectionItem{
                flex: 50%;
                padding: 0 2rem 0 2rem;

                .ServicesTitle{
                    font-size: 3rem;
                    color: '#222835';
                    font-weight: bold;
                }
        
                .ServicesItem{
                    margin-top: 2.5rem;

                    .ServiceItemTitleWrapper{
                        display: flex;
                        align-items: center;
                        .Circle{
                            width: 10px;
                            height: 10px;
                            border-radius: 50%;
                            background-color: #1976D2;
                        }
                        .ServicesItemTitle{
                            margin-left: 1rem;
                            font-size: 1.7rem;
                            font-weight: bold;
                            color: #1976D2;
                        }
                    }

                    .ServicesItemDescription{
                        margin-left: 1.6rem;
                        font-size: 1.3rem;
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 600px) {
        .ServicesSection{
            background-image: linear-gradient(to bottom, white 50%, #F3F4F5 50%);

            .ServicesSectionItems{
                flex-direction: column;

                .ServicesSectionItem{
                    padding: 0 !important;
                }
            }
        }
    }
    // // SERVICES SECTION


    // WITH LEXDOKS SECTION
    .WithLexdoksSection{
        background-image: linear-gradient(204.33deg, #1976D2 15.57%, #004F9C 92.23%);
        color: #fff;

        .WithLexdoksSectionItems{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5rem 0 5rem 0;

            .WithLexdoksSectionItem{
                flex: 50%;
                padding: 0 2rem 0 2rem;

                .WithLexdoksDescription{
                    font-size: 1.3rem;
                    font-weight: 400;
                }

                .RegisterButton{
                    margin-top: 3rem;
                    width: 12rem;
                    border-radius: 100px;
                    font-size: 1rem;
                    background-color: #fff;
                    color: #1776d1;
                    border-color: #fff;
                }

                .RegisterButton:hover {
                    color: #fff;
                    background-color: #222835;
                }
            }
        }
    }
    @media only screen and (max-width: 600px) {
        .WithLexdoksSectionItems{
            flex-direction: column;

            .WithLexdoksSectionItem{
                padding: 0 !important;
            }
        }
    }
    // // WITH LEXDOKS SECTION


    // CLIENTS SECTION
    .ClientsSection{
        background-color: #FBFCFC;
        padding-top: 4rem;
        padding-bottom: 2rem;

        .ClientsSectionTitle{
            font-size: 2.5rem;
            text-align: center;
            margin-bottom: 4rem;
        }
        .ClientTitle{
            font-weight: bold;
            font-size: 1.3rem;
        }
        .ClientProffesion{
            color: #5D616A;
            font-size: 1.1rem;
            font-style: italic;
        }
        .ClientMessage{
            color: #5D616A;
            font-size: 1rem;
            line-height: 1.2;
            margin-top: 5px;
        }
    }
    @media only screen and (max-width: 600px) {
        
    }
    // // CLIENTS SECTION


    // PARTNERS SECTION
    .PartnersSection{
        background-color: #DFE7EE;

        .PartnersSectionItems{
            padding: 2rem 0 2rem 0;
            display: grid;
            grid-template-columns: 30% 70%;
            align-items: center;
        }

        .PartnersSectionTitle{
            font-weight: bold;
            font-size: 2.5rem;
        }

        .PartnerImg{
            width: 90px;
            height: 90px;
            border-radius: 50%;
        }
    }

    @media only screen and (max-width: 600px) {
        .PartnersSectionItems{
            grid-template-columns: 100% !important;
        }

        .PartnersSectionTitle{
            text-align: center !important;
            margin-bottom: 10px !important;
        }

        .PartnerImg{
            width: 70px !important;
            height: 70px !important;
            margin: 0 2px !important;
        }
    }
    // // PARTNERS SECTION

    
    // FOOTER SECTION
    .FooterSection{
        background-color: #F3F4F5;
        padding: 2rem 0 2rem 0;

        p{
            padding-top: 4px;
        }

        .FooterItems{
            display: grid;
            grid-template-columns: 2fr 1fr 1fr;
        }

        .FooterLexdoksTitle{
            color: #1976D2;
            font-size: 1.8rem;
            font-weight: bold;
            margin-bottom: 0.7rem;
        }

        .FooterSubtitle{
            color: #222835;
            font-size: 1.5rem;
            margin-bottom: 0.7rem;
            font-weight: bold;
        }

        .FooterInfoIconWithText{
            display: flex;
            align-items: center;
            padding-top: 4px;
            
            span{
                margin-left: 0.3rem;
            }
        }

        .FooterInfoIconWithTextLink{
            display: flex;
            align-items: center;
            cursor: pointer;
            padding-top: 4px;
            
            span{
                margin-left: 0.3rem;
                text-decoration: underline;
            }
        }
    }

    @media only screen and (max-width: 600px) {
        .FooterItems{
            grid-template-columns: 100% !important;
        }
        .FooterItems{
            text-align: center !important;
        }
        .FooterSubtitle{
            margin-top: 15px !important;
        }
        .FooterInfoIconWithText{
            justify-content: center !important;
        }
        .FooterInfoIconWithTextLink{
            justify-content: center !important;
        }
    }
    // // FOOTER SECTION
}



// ANIMATION
@keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translateX(300px);
    }
    to {
      opacity: 1;
    }
}

// Language dropwdonw

.LanguageDropdown {
    position: relative;
    display: inline-block;
    cursor: pointer;
}
  
.LanguageDropdownContent {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 120px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    right: 0;
}
  
.LanguageDropdown:hover .LanguageDropdownContent {
    display: block;
}

.LanguageDropdownContentItem{
    padding: 5px 10px;
    display: flex;
    align-items: center;
}

.LanguageDropdownContentItem:hover {
    background-color: #DFE7EE;
}