

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.slide{
  margin: 4.5rem 0;
  border: 3px solid rgba(25, 118, 210, 0.5);
  text-align: center;
  padding: 0 20px 20px 20px;
  transform: scale(0.7);
  transition: transform 300ms;
  opacity: 0.5;
  border-radius: 10px;
}

.slide img{
  margin: 0 auto;
  margin-top: -50px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.activeSlide{
  transform: scale(1.1);
  opacity: 1;
}

.slidePartners{
  margin: 0 10px;
  height: 90px;
}

.slidePartners img {
  margin: 15px auto 0 auto;
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.slidePartners.notRounded img {
  border-radius: 0;
  width: auto;
}


.slidePartnersText {
  text-align: center;
  padding: 1.5rem 1rem 0 1rem !important;
}

.slidePartnersText a {
  color: #282c34;
  text-decoration: none;
}

.arrow{
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

.next {
  right: 0%;
  top: 50%;
}

.prev {
  left: 0%;
  top: 50%;
}

/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }


.css-bg8hop-MuiInputBase-root-MuiOutlinedInput-root{
  border-radius: 0.8rem !important;
}

.css-1tq161j-MuiInputBase-root-MuiOutlinedInput-root{
  border-radius: 0.8rem !important;
}

.css-u4xq9y{
  border-radius: 0.8rem !important;
}

.css-kct39y{
  border-radius: 0.8rem !important;
}
